import { defineStore, skipHydrate } from 'pinia'

export const useAuthStore = defineStore('AuthStore', () => {
    
    const openverseAccessToken = ref<{ key: string, expiry: number } | null>(null)

    const setOpenverseAccessToken = (key: string, expiry: number) => {
        openverseAccessToken.value = { key, expiry }
    }

    return{
        openverseAccessToken,
        setOpenverseAccessToken,
    }
    
})
