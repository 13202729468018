<template>
  <NavigationMenu>
    <NavigationMenuList v-if="!route.params.project">
      <div class="flex w-full scale-[1] sm:scale-[1] origin-left pl-0">
        <div class="squircle-10 bg-background p-2 hidden sm:block ml-1">
        
          <NuxtLink class="cursor-pointer" to="/">
          <p class="rounded-md text-xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.popover.foreground),theme(colors.muted.foreground),theme(colors.popover.foreground),theme(colors.muted.foreground),theme(colors.popover.foreground))] bg-[length:200%_auto] animate-gradient tracking-[-0.05em]">SuperSeeded</p>
          </NuxtLink>
          
      </div>
        <NavigationMenuItem v-for="menu in props.menuItems" :key="menu.title">
          <NavigationMenuTrigger @click="navigateTo(menu.item.href)" class="mx-0 px-2 sm:mx-1 sm:px-6 bg-transparent no-chevron squircle-1  0">
            <Icon v-if="menu.item?.icon" :name="menu.item.icon" size="1.8em" class="mr-2 menu-icon" />
            <span class="text-[1.1em] sm:text-[1.3em] font-regular sm:font-semibold">{{menu.item.title}}</span>
          </NavigationMenuTrigger>
          <NavigationMenuContent class="z[10000]">
            <div v-if="!menu?.image?.src">
              <ul class="grid w-[400px] max-w-[70vw] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                <li class="row-span-3">
                  <NavigationMenuLink as-child>
                    <a class="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted no-underline outline-none focus:shadow-md"
                      href="/">
                      
                      <NuxtImg v-if="menu?.item?.image?.src" :src="menu?.item?.image?.src" class="h-fill w-fill p-0 m-0 rounded-md" />

                      <!-- <div class="mb-2 mt-4 text-lg font-medium">

                      </div> -->
                      <p class="text-sm leading-tight text-muted-foreground">
                        <!-- some description here -->
                      </p>
                    </a>
                  </NavigationMenuLink>
                </li>
                <NavbarListItem v-for="subMenu in menu.subMenuItems" :key="subMenu.title" :title="subMenu.title"
                  :href="subMenu.href" :icon="subMenu.icon">
                  {{ subMenu.description }}
                </NavbarListItem>
              </ul>
            </div>
            <div v-else>
              <ul class="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                <li class="row-span-3">
                  <NavigationMenuLink as-child v-if="menu.image.title || menu.image.description">
                    <a class="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
                      href="/">
                      <img height="350" :src="menu.image.src" class="h-fill w-fill p-0 m-0" />
                      <div class="mb-2 mt-4 text-lg font-medium">
                        {{menu.image.title}}
                      </div>
                      <p class="text-sm leading-tight text-muted-foreground">
                        {{menu.image.description}}
                      </p>
                    </a>
                  </NavigationMenuLink>
                  <img v-else:src="menu.image.src" class="h-fill w-fill p-0 m-0" />
                </li>
                <NavbarListItem v-for="subMenu in menu.subMenuItems.filter(e => !e.image)" :key="subMenu.title"
                  :title="subMenu.title" :href="subMenu.href" :icon="subMenu?.icon">
                  {{ subMenu.description }}
                </NavbarListItem>
              </ul>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>

        
      </div>
    </NavigationMenuList>
    <div v-else class="ml-0 sm:-ml-16">
      <MyBreadcrumbs />
    </div>
  </NavigationMenu>

</template>

<script setup>
import { navigationMenuTriggerStyle } from '@/components/ui/navigation-menu'
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
} from '@/components/ui/navigation-menu'

useSquircleObserver()

const route = useRoute();

const props = defineProps({
  menuItems: {
    type: Array,
  }
})

</script>


<style>
.no-chevron svg {
  display: none;
}

@media (max-width: 640px) { /* Tailwind's breakpoint for 'sm' */
  button svg.lucide-chevron-down-icon {
    display: none !important;
  }
}

div[id^="radix-vue-dropdown-menu-content"] {
  box-shadow: 0px 5.8px 1.4px -13px rgba(0, 0, 0, 0.058),
  0px 10.2px 3.3px -13px rgba(0, 0, 0, 0.078),
  0px 13.7px 6.3px -13px rgba(0, 0, 0, 0.09),
  0px 17.1px 11.2px -13px rgba(0, 0, 0, 0.1),
  0px 22.2px 20.9px -13px rgba(0, 0, 0, 0.113),
  0px 39px 50px -13px rgba(0, 0, 0, 0.15) !important;
  border: none !important;
}

</style>