<script setup lang="ts">
import { AccordionItem, type AccordionItemProps } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = withDefaults(defineProps<AccordionItemProps & {
  class?: string
  border?: boolean
}>(), {
  border: true
})
</script>

<template>
  <AccordionItem
    v-bind="props"
    :class="cn(props.border && 'border-b', props.class ?? '')"
  >
    <slot />
  </AccordionItem>
</template>
