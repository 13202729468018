export default defineNuxtRouteMiddleware(async (to) => {
    const user = useSupabaseUser()
    const supabase = useSupabaseClient()
    
    // Skip auth check for auth-related pages
    if (to.path.includes('/login') || to.path.includes('/confirm')) {
        return
    }

    if (!user.value) {
        return navigateTo('/login')
    }

    // Check MFA status
    const { data } = await supabase.auth.mfa.listFactors()
    const hasMfaEnabled = data?.totp?.length > 0
    
    if (hasMfaEnabled) {
        // Get current authentication level
        const { data: { currentLevel } } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel()
        
        // If MFA is enabled but not completed, redirect to confirm page
        if (currentLevel === 'aal1') {
            return navigateTo('/confirm')
        }
    }
})
