
import * as imagekitRuntime$8Nj7BhwNOO from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/imagekit'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imagekit",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imagekit']: { provider: imagekitRuntime$8Nj7BhwNOO, defaults: {"baseURL":"https://ik.imagekit.io/8qxqs9cznn"} }
}
        