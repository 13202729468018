<script lang="ts" setup>
const userStore = useUserStore();
const { setSelectedTeam } = userStore;
const { selectedTeam } = storeToRefs(userStore)

import { cn } from "@/lib/utils";
import { PopoverClose, DialogPortal } from "radix-vue";

const checkGroup = defineModel('checkGroup');
const isTeamOwner = defineModel('isTeamOwner');

defineProps({
  groups: {
    type: Array,
    default: () => [],
  },
  // selectedTeam: {
  //   type: Object,
  //   default: () => {},
  // }
});

const subMenuActive = ref('')


const selectTeam = async (team: any) => {
  setSelectedTeam(team);  
  if (team.metadata.type == 'Personal') {
    await navigateTo(`/user/${team?.id}`)
  } else {
    console.log('navigating to team', team)
    await navigateTo(`/team/${team?.id}`)
  }
}


</script>

<template>
  <div class="">

    <CommandGroup v-for="group in groups" :key="group.label" :heading="group.label">

      <CommandItem :key="`${selectedTeam?.id}-${team?.id}`" v-for="team in group.teams.filter(group.filter)"
        :value="team" :class="[
                  'group text-sm cursor-pointer',
                  { '!bg-muted !brightness-90': selectedTeam?.id === team?.id },
                  
                ]" @select="
                  () => {
                    selectTeam(team)
                  }
                ">
        <Avatar class="mr-2 h-5 w-5">
          <AvatarImage :src="`https://avatar.vercel.sh/${team?.metadata?.name}.png`" :alt="team?.metadata?.name"
            class="" />
          <AvatarFallback>{{ team?.metadata?.name?.charAt(0) }}</AvatarFallback>
        </Avatar>
        <TooltipProvider :delayDuration="0" v-if="team?.metadata?.description">
          <Tooltip>
            <TooltipTrigger>
              <span>
                {{ team?.metadata?.name }}
              </span>
            </TooltipTrigger>
            <TooltipContent align="start" side="left" class="select-none pointer-events-none">
              <p>{{ team?.metadata?.description }}</p>

            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <span v-else>
          {{ team?.metadata?.name }}
        </span>

        <Icon name="lucide:check" :class="
                    cn(
                      'ml-auto h-4 w-4 ml-3',
                      selectedTeam?.id === team?.id
                        ? 'opacity-100'
                        : 'opacity-0'
                    )
                  " />
        <div class="flex grow"></div>

        <Popover v-if="group.subMenu.length" @update:open="subMenuActive = $event ? team.id : ''">
          <PopoverTrigger as-child>
            <Button @click.stop="checkGroup = team.id" variant="text"
              :class="[{'opacity-100 sm:opacity-0': subMenuActive != team.id },'group-hover:opacity-100 p-0 m-0 h-1 w-4']">

              <Icon name="lucide:ellipsis-vertical" />

            </Button>
          </PopoverTrigger>
          <!-- only display when not personal account -->
          <PopoverContent class="p-0 px-0 max-w-36 shadow-xl mr-8">
            <!-- Popover content goes here -->
            <div class="p-2 m-0 ">
              <PopoverClose v-for="action in group.subMenu">
                <Button @click="action.action(team)" class=" h-8 font-normal tracking-tight" variant="ghost">


                  <span v-if="action.label.member=='Mute'" class="">
                    <Icon class="mr-4" :name="team?.metadata?.muted ? 'lucide:volume-2' : 'lucide:volume-x'" />
                    <span v-if="team?.metadata?.muted">Unmute</span>
                    <span v-else>Mute</span>
                  </span>
                  <span v-else>
                    <Icon class="mr-4" :name="action.icon" />
                    <span v-if="isTeamOwner" class="">{{ action.label.owner }}</span>
                    <span v-else class="">{{ action.label.member }}</span>
                  </span>
                </Button>
              </PopoverClose>
            </div>
          </PopoverContent>
        </Popover>
      </CommandItem>

      <!-- accordian was here -->

      <CommandSeparator v-if="group.label == 'Personal Account'" class="my-2" />
    </CommandGroup>

  </div>
</template>

<style scoped></style>
