<script setup lang="ts">
import { useGeolocation, useMousePressed, onClickOutside, onKeyStroke, useFocusWithin } from '@vueuse/core'
import { distance } from '@turf/distance'
import { point } from '@turf/helpers'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
dayjs.extend(relativeTime)
dayjs.extend(calendar)
import { titleCase } from "title-case";
import { ref, computed } from 'vue'

const { coords } = useGeolocation()
const { addPlantToDock } = useUiStore()

const { placeholder } = defineProps({
  placeholder: {
    type: String,
    required: true,
  },
});

const inputValue = ref('')
const openDropDown = ref(false)
const dropdown = ref(null)
const itemSelected = ref(false)
const inputRef = ref(null)
onMounted(() => {
  // Focus the input element when the component is mounted
  inputRef.value?.$el.focus()
})

let { focused: inputFocused } = useFocusWithin(inputRef)
const allowBlur = ref(false)
const { pressed: mousePressed } = useMousePressed()

const emit = defineEmits(['select'])

const { fetchSpeciesDataIndividually } = useSearch()

const dropdownMenuContentRef = ref(null)
const dropdownMenuItemRef = ref([])
const images = ref([])

const names = ref([])

const namesDebounced = ref([])

const debouncedFetch = useDebounceFn(async () => {
  if (inputValue.value && inputValue.value.trim().length > 0) {
    const results = await fetchSpeciesDataIndividually([inputValue.value], {
      coords: {latitude: Infinity, longitude: Infinity},
      limit: 20,
      distinct: 'species',
      sort: [`_geoPoint(${coords.value.latitude}, ${coords.value.longitude}):asc`]
    })
    names.value = results.length > 0 ? results : []
  } else {
    names.value = []
    openDropDown.value = false
  }
}, 500)

watch(inputValue, () => {
  debouncedFetch()
})

watch(names, () => {
  if (names.value?.length && !itemSelected.value) {
    openDropDown.value = true;
  } else {
    openDropDown.value = false;
  }
})

const tnrsData = ref(null)
const tnrsError = ref(null)

const fetchTnrs = async () => {
  try {
    const { data, error } = await useLazyAsyncData(
      'plantTnrs',
      () => $fetch('/api/bio/tnrs', {
        method: 'POST',
        body: { searchQuery: inputValue.value }
      }),
      {
        immediate: false,
        getCachedData: (key) => {
          return useNuxtApp().payload.data[key] || null;
        }
      }
    )
    
    tnrsData.value = data.value
    tnrsError.value = error.value
  } catch (e) {
    console.error('Error fetching TNRS data:', e)
    tnrsError.value = e
  }
}

watchDebounced(inputValue, async() => {
  if (inputValue.value && inputValue.value.trim().length > 0) {
    fetchTnrs()
  } else {
    tnrsData.value = null
    tnrsError.value = null
  }
}, { debounce: 500, immediate: false })

// Remove this watch as it's just logging the value
// watch(tnrsData, () => {
//   console.log(tnrsData.value)
// })

const { data: searchImages, error: searchImagesError, refresh: imagesRefresh } = await useLazyAsyncData(
  'plantSearchImages',
  () => $fetch('/api/bio/search', {
    method: 'POST',
    body: { searchQuery: namesDebounced.value }
  }),
  {
    watch: [inputValue],
    server: false,
    getCachedData: (key) => {
      return useNuxtApp().payload.data[key] || null;
    }
  }
);

watchDebounced(names, (newNames: any) => {
  // if (newNames && !newNames[0].some((name: any) => name?.images)) {
    namesDebounced.value = newNames?.[0].map((name: any) => name.species)
    // imagesRefresh()
    // console.log(newNames[0].map(name => name.species))
  // }
}, { debounce: 500 })


onClickOutside(inputValue, (event) => {
  // Commented out for consistency with AddressInput
  // allowBlur.value = true
  inputRef.value?.$el.blur()
  openDropDown.value = false
})

// ... rest of the script setup ...

// Modify handleDropdownSelect to work with Typesense results
const handleDropdownSelect = (item: any, index: number) => {
  if (!item?.species) {
    console.warn('Invalid plant item:', item);
    return;
  }

  const selectedItem = {
    id: `${item.species}-${Date.now()}`, // Ensure unique ID
    species: item.species,
    photo: searchImages.value?.images?.[index]?.url 
      ? `https://ik.imagekit.io/8qxqs9cznn/${searchImages.value.images[index].url}`
      : `https://s3.us-east-1.wasabisys.com/superseeded/images/converted_images/${item.species.replace(/\s+/g, '_')}/0001.jpg`,
    location: item.location,
    family: item.family,
    eventDate: item.eventDate,
    coordinateUncertaintyInMeters: item.coordinateUncertaintyInMeters
  };

  // Add to dock
  addPlantToDock(selectedItem);
  
  // Close dropdown and update input
  openDropDown.value = false;
  inputValue.value = item.species;
  itemSelected.value = true;
  
  // Reset itemSelected after a delay
  setTimeout(() => {
    itemSelected.value = false;
  }, 500);

  // Emit select event
  emit('select', selectedItem);
};


  // onClickOutside(document, () => {
  //   allowBlur.value = true
  // })


watch(inputFocused, (focusState) => {
  if (focusState == false && allowBlur.value == false) {
    if (!mousePressed.value) {
      inputRef.value?.$el.focus()
    }
  }
})

onKeyStroke(['Escape'], (e) => {
  openDropDown.value = false
  allowBlur.value = false
  inputFocused.value = true
  inputRef.value?.$el.focus()
})

onKeyStroke(['Backspace', 'Delete'], (e) => {
  allowBlur.value = false
  inputRef.value?.$el.focus()
  if (import.meta.client) {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Backspace' }))
  }
})

onKeyStroke(['Enter'], (e) => {
  if (!inputFocused.value) {
    openDropDown.value = false
    allowBlur.value = false
  }
})

onKeyStroke(['ArrowUp'], (e) => {
  if (import.meta.client) {
    const activeElement = document.activeElement
    if (activeElement == dropdownMenuItemRef?.value?.[0]?.$el) {
      // inputRef.value?.$el.focus()
    }
  }
})

onKeyStroke(['Tab', 'ArrowDown', 'Enter'], (e) => {
  if (inputFocused.value) {
    dropdownMenuItemRef?.value?.[0]?.$el?.focus()
    if(inputFocused.value==false) {
      allowBlur.value = true 
    }
  } 
})

// Define Melbourne's coordinates as a fallback
const MELBOURNE_COORDS = { latitude: -37.8136, longitude: 144.9631 }

// Create a computed property for the current location
const currentLocation = computed(() => {
  return coords.value?.latitude && coords.value?.longitude && 
         isFinite(coords.value.latitude) && isFinite(coords.value.longitude)
    ? coords.value
    : MELBOURNE_COORDS
})

const computeDistance = (location) => {
  console.log('Location:', location);
  console.log('Current location:', currentLocation.value);

  if (!location || !Array.isArray(location) || location.length !== 2) {
    console.log('Invalid location data');
    return 'N/A';
  }
  const [longitude, latitude] = location;
  if (typeof longitude !== 'number' || typeof latitude !== 'number') {
    console.log('Invalid longitude or latitude');
    return 'N/A';
  }
  var from = point([currentLocation.value.longitude, currentLocation.value.latitude]);
  var to = point([longitude, latitude]);
  var options = { units: "kilometers" };
  const dist = distance(from, to, options);
  console.log('Calculated distance:', dist);
  return isNaN(dist) ? 'N/A' : dist.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' km';
}

const { width: inputWidth } = useElementSize(inputRef)

</script>

<template>
  <div class="m-0 p-0 flex w-full flex-col justify-start" :class="$attrs.class">
    <!-- TODO: change to Tags-Input (shadcn) -->
    <Input ref="inputRef" v-model="inputValue" :placeholder="placeholder"
      class="text-lg font-semibold tracking-tight text-muted-foreground" />
    <div v-show="tnrsData?.[0]?.Name_matched && inputValue.length" class="text-xs text-muted-foreground">
      <VTooltip :animate="true" side="right"
        :content="{__html: `<div class='max-w-[400px]'>Taxonomic Name Resolution Service, Boyle, B. L., Matasci, N., Mozzherin, D., Rees, T., Barbosa, G. C., Kumar Sajja, R., & Enquist, B. J. (2021). Taxonomic Name Resolution Service, version 5.1. In Botanical Information and Ecology Network. https://tnrs.biendata.org/</div>`}">
        <div class="flex flex-row gap-2 mt-2 pl-1 text-[0.9em]">
          <img src="/bien.png" class="w-8 h-auto -mt-[2px] self-center" />

          <a target="_blank" class="underline" :href="tnrsData?.[0]?.Name_matched_url">{{
            tnrsData?.[0]?.Name_matched_url }}
            <Icon name="radix-icons:open-in-new-window" class="h-3 w-3 ml-1 -mt-[2px]" />
          </a>
        </div>
      </VTooltip>
    </div>
    <DropdownMenu v-if="names?.[0]?.length > 0" ref="dropdown" :open="openDropDown">
      <DropdownMenuTrigger class="mt-2 relative">
      </DropdownMenuTrigger>
      <KeepAlive>
        <DropdownMenuContent ref="dropdownMenuContentRef" align="start"
          :class="['scale-[1.5] origin-top-left relative border-none pb-3 shadow-xl justify-start self-start items-start -mt-1 mx-0 px-0 dark:bg-background', { 'opacity-0': !names?.length }]">
          <ScrollArea class="h-[25vh] w-full  overflow-visible" :style="{ width: `${inputWidth*1.06}px` }">

            <DropdownMenuItem class="cursor-pointer !hover:bg-muted/20 -mr-3 flex flex-col items-start"
              ref="dropdownMenuItemRef" :style="{ width: inputWidth * 0.998 + 'px'}" v-for="(item, index) in names[0]"
              :key="item.species" @select="handleDropdownSelect(item, index)">
              <div class="flex flex-row gap-2">
                <div class="min-w-10">
                  <VTooltip :animate="true" side="left" :content="`Source: Wikimedia Commons`">
                    <img @load="" v-if="searchImages?.images?.[index]?.url"
                      :src="`https://ik.imagekit.io/8qxqs9cznn/${searchImages?.images?.[index]?.url}`" loading="lazy"
                      class="w-12 h-12 rounded-full" />
                  </VTooltip>
                </div>
                <div class="flex flex-col justify-between h-full">
                  <div class="w-full text-lg">{{ item.species }}</div>
                  <div class="text-muted-foreground text-md">{{ item.family }}
                    <VTooltip :animate="true" side="bottom"
    :content="{ __html: `<span class='font-bold'>Distance (km) to nearest specimen${currentLocation.value === MELBOURNE_COORDS ? ' from Melbourne, Australia' : ''}. ${Math.abs(dayjs.unix(item.eventDate).diff(dayjs(), 'year'))} year old (± ${item.coordinateUncertaintyInMeters} meters)</span><br/>Source: GBIF [recorded ${dayjs.unix(item.eventDate).format('MMM D, YYYY')}]`}">
    <span
      class="text-muted-foreground text-xs bg-muted-foreground/10 rounded-full px-[6px] py-[3px] font-regular">
      <Icon name="hugeicons:location-05" class="w-4 h-4 -mt-[3px]" />
      {{computeDistance(item.location)}}
    </span>
  </VTooltip>

                  </div>
                </div>
              </div>
            </DropdownMenuItem>
          </ScrollArea>
        </DropdownMenuContent>
      </KeepAlive>
    </DropdownMenu>
    <!-- {{ namesDebounced }} -->
  </div>
</template>

<style scoped>

#plant-input-dropdown {
  width: 100% !important;
}
</style>
