<template>
  <div
    :class="[$attrs.class, 
      cn(
        'squircle-15 relative mx-auto min-h-fit w-full  cursor-pointer overflow-hidden p-4',
        // animation styles
        'transition-all duration-200 ease-in-out hover:scale-[103%]',
        // light styles
        'bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]',
        // dark styles
        'transform-gpu dark:bg-transparent dark:backdrop-blur-md dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]'
      )]
    "
    @click="onNotificationClick"
  >
    <div class="flex flex-row items-start">
      <div class="flex mr-10 items-start justify-start">
        <Icon :name="currentIcon" class="w-5 h-5 max-w-5 max-h-5" :class="`text-${color}-500`" />
      </div>
      <div class="flex flex-col overflow-hidden self-start relative -top-2">
        <div
          class="flex flex-row items-center whitespace-pre text-lg font-medium dark:text-white relative top-[2px]"
        >
          <span class="text-sm sm:text-md tracking-tight select-none">{{ item?.title }}</span>
          <span class="mx-1 text-muted-foreground/40 relative top-[1px]">·</span>
          
          <span class="text-xs text-muted-foreground/40 select-none">{{ formattedTime }}</span>
        </div>
        <p class="text-sm font-normal dark:text-white/60 select-none">{{ item?.message }}</p>
      </div>
    </div>
    <button
      v-if="item?.dismissible"
      @click.stop="dismiss"
      class="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
    >
      <Icon name="solar:close-circle-linear" class="w-4 h-4" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { cn } from "~/lib/utils";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNotivue } from 'notivue';
import { useIntervalFn } from '@vueuse/core';

dayjs.extend(relativeTime);

useSquircleObserver()

interface Item {
  title: string;
  message: string;
  dismissible?: boolean;
  onClick?: () => void;
  onDismiss?: () => void;
  promise?: Promise<any>;
}

interface NotificationProps {
  item?: Item;
  time?: string | Date;
  icon?: string;
  color?: string;
}

const props = withDefaults(defineProps<NotificationProps>(), {
  icon: 'solar:check-circle-linear',
  color: 'green',
  time: () => new Date()
});

const { dismiss } = useNotivue();

const formattedTime = ref(dayjs(props.time).fromNow());

// Update the formatted time every minute
useIntervalFn(() => {
  formattedTime.value = dayjs(props.time).fromNow();
}, 60000);

const onNotificationClick = () => {
  if (props.item.onClick) {
    props.item.onClick();
  }
};

const handleDismiss = () => {
  dismiss(props.item);
  if (props.item.onDismiss) {
    props.item.onDismiss();
  }
};

const currentIcon = computed(() => {
  if (props?.item?.promise) {
    return 'line-md:loading-loop';
  }
  return props.icon;
});

// Handle promise resolution
watch(() => props?.item?.promise, (promise) => {
  if (promise && promise instanceof Promise) {
    promise.then(() => {
      // Update icon or handle success
      currentIcon.value = 'line-md:uploading-loop';
    }).catch(() => {
      // Handle error if needed
      currentIcon.value = 'carbon:error-outline';
    });
  }
}, { immediate: true });
</script>
