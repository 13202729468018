<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'

const uiStore = useUiStore()
const { bottomSheetOpen, bottomSheetDynamicComponent, bottomDrawerHeight, uploadConsent, showAlert, bottomDrawerConfig, useConfigurableBottomDrawer, bottomDrawerConfirmEnabled, viewMode } = storeToRefs(uiStore)
const { toggleBottomSheet, setBottomSheetDynamicComponent, handleBottomDrawerAction, setBottomDrawerConfirmEnabled } = uiStore

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  DrawerOverlay
} from '@/components/ui/drawer'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import { useSquircleObserver } from '@/composables/useSquircle'
import { useMediaQuery } from '@vueuse/core'

useSquircleObserver()
const hideLargeDropArea = ref(false)
const {$uppy} = useNuxtApp()
const isDesktop = useMediaQuery('(min-width: 768px)')

const onDrop = (e) => {
  e.preventDefault();
  const items = e.dataTransfer.items;

  for (const item of items) {
    const entry = item.webkitGetAsEntry();
    if (entry && entry.isFile) {
      entry.file((file) => {
        // Check if the file is valid (e.g., by type or other criteria)
        if (isValidFile(file)) {
          $uppy.addFile({
            name: file.name,
            type: file.type,
            data: file, // file blob
            meta: {
              relativePath: entry.relativePath,
            },
            source: 'Local', // optional, determines the source of the file, for example, Instagram.
            isRemote: false, // optional, set to true if actual file is not in the browser, but on some remote server, for example,
            // when using companion in combination with Instagram.
          });
        }
      });
    }
  }
}

// Function to validate the file
const isValidFile = (file) => {
  // Add your validation logic here (e.g., check file type, size, etc.)
  const validTypes = ['image/jpeg', 'image/png', 'application/pdf']; // Example valid types
  return validTypes.includes(file.type);
}

const selectedData = ref(null)

const handleAction = (action: string) => {
  handleBottomDrawerAction(action, selectedData.value)
}

const dynamicComponentRef = ref(null)

const onValidate = (isValid: boolean, data: any) => {
  setBottomDrawerConfirmEnabled(isValid)
  selectedData.value = data
}

const useDesktopView = computed(() => {
  return viewMode.value === 'desktop' || (viewMode.value === 'auto' && isDesktop.value)
})

watch(bottomSheetOpen, (val) => {
  if(val==true) {
    hideLargeDropArea.value = false
  } else{
    // setBottomSheetDynamicComponent(null)
  }
})


</script>

<template>
  <ClientOnly>
    <Dialog v-if="useDesktopView" v-model:open="bottomSheetOpen" >
      <DialogContent class=" sm:max-w-[80vw] max-w-[800px]" :class="{ 'pointer-events-none': showAlert }">
        <template v-if="useConfigurableBottomDrawer && bottomDrawerConfig">
          <DialogTitle>{{ bottomDrawerConfig.title }}</DialogTitle>
          <DialogDescription>{{ bottomDrawerConfig.description }}</DialogDescription>
          <component 
            v-if="bottomSheetDynamicComponent" 
            :is="bottomSheetDynamicComponent" 
            ref="dynamicComponentRef"
            @validate="onValidate"
          />
          <template v-else>
            <slot name="content"></slot>
          </template>
          <DialogFooter>
            <Button class="mb-3 mr-3"
              v-for="action in bottomDrawerConfig.actions" 
              :key="action.label"
              @click="handleAction(action.action, action.data)"
              :variant="action.variant || 'default'"
              :disabled="action.action === 'confirm' && !bottomDrawerConfirmEnabled"
            >
              {{ action.label }}
            </Button>
          </DialogFooter>
        </template>
        <component v-else-if="bottomSheetDynamicComponent" :is="bottomSheetDynamicComponent" />
      </DialogContent>
    </Dialog>

    <Drawer v-else v-model:open="bottomSheetOpen" :defaultOpen="false">
      <DrawerOverlay style="opacity: 0" />
      <DrawerContent :disableOutsidePointerEvents="false" :trapFocus="false" class="squircle-30 rounded-t-2xl" :class="{ 'pointer-events-none': showAlert }">
        <div v-if="uploadConsent && !hideLargeDropArea" @drop="onDrop" @dragenter.prevent
          @mouseup="hideLargeDropArea = true" @dragover.prevent :class="[`absolute w-full h-[170vh] -top-[50vh]`]">
        </div>
        <template v-if="useConfigurableBottomDrawer && bottomDrawerConfig">
          <DrawerHeader>
            <DrawerTitle>{{ bottomDrawerConfig.title }}</DrawerTitle>
            <DrawerDescription>{{ bottomDrawerConfig.description }}</DrawerDescription>
          </DrawerHeader>
          <component 
            v-if="bottomSheetDynamicComponent" 
            :is="bottomSheetDynamicComponent" 
            ref="dynamicComponentRef"
            @validate="onValidate"
          />
          <div v-else class="p-4">
            <slot name="content"></slot>
          </div>
          <DrawerFooter>
            <Button 
              v-for="action in bottomDrawerConfig.actions" 
              :key="action.label"
              @click="handleAction(action.action, action.data)"
              :variant="action.variant || 'default'"
              :disabled="action.action === 'confirm' && !bottomDrawerConfirmEnabled"
            >
              {{ action.label }}
            </Button>
          </DrawerFooter>
        </template>
        <component v-else-if="bottomSheetDynamicComponent" :is="bottomSheetDynamicComponent" />
      </DrawerContent>
    </Drawer>

    <AlertDialog :open="showAlert" @update:open="uiStore.toggleAlert">
      <AlertDialogContent class="border-0 shadow-xxl z-50">
        <component :is="uiStore.alertDialogDynamicComponent" v-if="uiStore.alertConfig" />
      </AlertDialogContent>
    </AlertDialog>
  </ClientOnly>
</template>

<style>

div[role="dialog"] {
  margin: 0 min(3vw, 50px);
  padding: 0 !important;
}

div[data-state="open"][vaul-drawer-visible="true"][vaul-overlay][vaul-snap-points="false"][vaul-snap-points-overlay="true"] {
 opacity: 0.4 !important;
}

div[data-state="open"][vaul-drawer-visible="true"]{
  border: none !important;
}

/* .v-dialog {
  z-index: 9999 !important;
} */
</style>